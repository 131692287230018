import styled from "styled-components";
import { theme } from "./../../theme";

export const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Coin = styled.img`
  border-radius: 50%;
  width: 30px;
`;

export const CurrencyAndNetwork = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const CurrencyText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;
export const NetworkText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #858585;
`;

export const ActionButton = styled.button`
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14.3506px;
  line-height: 17px;
  text-decoration-line: underline;
  background: ${theme.colors.textBackground};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
`;
