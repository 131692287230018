import { FC } from "react";
import QRCode from "qrcode";

interface IQRCode {
  url: string;
}

const QRCodeScan: FC<IQRCode> = ({ url }) => {
  return (
    <div>
      <img src={url} style={{ width: "200px" }} />
    </div>
  );
};

export default QRCodeScan;
