/* eslint-disable no-unused-vars */
import React from "react";
import { Title } from "../components/styles";
import Filledbutton from "../components/Reusables/Buttons/Filledbutton";
import {
  AuthPageBanner,
  AuthPageContainer,
  ExtraInput,
  FormLink,
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  FormFooter,
  FooterText,
  FormLeftContainer,
  Desc,
} from "../components/Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { errorMessage, getFormDetails } from "../Redux/Reducers/formReducer";
import { Check2FAEnabled, login } from "../Apis/accountProcesing";
import { validateemail } from "../Helpers/generals";

const FAAuth = () => {
  const { error, loading, registeredEmail } = useAppSelector(
    (state) => state.form
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!validateemail(registeredEmail)) {
      dispatch(errorMessage("Please fill in correct email"));
    } else {
      dispatch(errorMessage(""));
      Check2FAEnabled(registeredEmail, navigate);
    }
  };
  return (
    <AuthPageContainer>
      <FormLeftContainer href="https://switchwallet.io">
        <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </FormLeftContainer>
      <FormContainer>
        <Form>
          <div style={{ marginBottom: "30px" }}>
            <Title>Welcome Back</Title>
          </div>
          <Desc>Enter your email </Desc>

          <InputContainer>
            <Label>Email</Label>
            <Input
              type="email"
              placeholder="Enter email"
              name="registeredEmail"
              onChange={handleChange}
            />
          </InputContainer>
          <Filledbutton
            text="Log in"
            handleClick={handleClick}
            loading={loading}
          />
          <FormFooter>
            <FooterText>Do not have an account? </FooterText>
            <FormLink to="/create-account">Sign up</FormLink>
          </FormFooter>
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </Form>
      </FormContainer>
    </AuthPageContainer>
  );
};

export default FAAuth;
