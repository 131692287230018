/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { Title } from "../components/styles";
import Filledbutton from "../components/Reusables/Buttons/Filledbutton";
import UnFilledbutton from "../components/Reusables/Buttons/PrimaryButton";
import * as LoginStyles from "../components/Generals/LoginStyles";
import { Desc } from "../components/Reusables/Cards/IconCard";
import OTPInputs from "../components/Reusables/Inputs/OTPInputs";
import { compileOtp, errorMessage } from "../Redux/Reducers/formReducer";
import {
  Confirm2FAOTP,
  sendCreateAcoountEmail,
} from "../Apis/accountProcesing";
import { validateemail } from "../Helpers/generals";

const Confirm2FAOtp = () => {
  const navigate = useNavigate();
  const { otp, error, loading } = useAppSelector((state) => state.form);
  const email = localStorage.getItem("email") || "";
  const dispatch = useAppDispatch();

  // verify and reset otp handlers
  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const filteredOTP = otp.filter((x) => x.length !== 0);
    if (filteredOTP.length !== 6) {
      dispatch(errorMessage("OTP is not complete"));
    } else {
      Confirm2FAOTP(email, filteredOTP.join(""), navigate);
    }
  };
  const handleResend = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!email || !validateemail(email)) {
      dispatch(errorMessage("Please fill in a valid email"));
    } else {
      sendCreateAcoountEmail(email, navigate);
    }
  };

  //   useEffect(() => {
  //     if (!email) navigate("/create-account");
  //   });

  return (
    <LoginStyles.AuthPageContainer>
      <LoginStyles.FormLeftContainer href="https://switchwallet.io">
        <LoginStyles.AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </LoginStyles.FormLeftContainer>
      <LoginStyles.FormContainer>
        <LoginStyles.Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Title>2FA Authentication</Title>
            {/* <Desc>We have sent you a One Time Password to email</Desc> */}
          </div>

          <Desc style={{ marginBottom: "20px" }}>Please Enter Passcode</Desc>
          <LoginStyles.InputContainer>
            <OTPInputs />
          </LoginStyles.InputContainer>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              margin: "20px",
            }}
          >
            <Filledbutton
              text="Verify OTP"
              loading={loading}
              handleClick={handleSubmit}
            />
          </div>
          {/* <LoginStyles.FormFooter>
            <LoginStyles.FooterText>
              Already have an account?
            </LoginStyles.FooterText>
            <LoginStyles.FormLink to="/">Log in</LoginStyles.FormLink>
          </LoginStyles.FormFooter> */}
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </LoginStyles.Form>
      </LoginStyles.FormContainer>
    </LoginStyles.AuthPageContainer>
  );
};

export default Confirm2FAOtp;
