/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { FilledButton } from "./styles";
import { DotLoader } from "react-spinners";

interface FilledButtonProps {
  text: string;
  handleClick?: any;
  bg?: string;
  loading?: boolean;
}

const Filledbutton: FC<FilledButtonProps> = ({
  text,
  handleClick,
  bg,
  loading,
}) => (
  <FilledButton
    bg={bg}
    onClick={handleClick && handleClick}
    disabled={loading}
    loading={loading && loading}
  >
    {loading ? <DotLoader color="#ff6600" size={20} /> : text}
  </FilledButton>
);

export default Filledbutton;
