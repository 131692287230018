export const currencyList = [
  {
    name: "XEND",
    Symbol: "/assets/images/currenciess/xend.png",
    value: 7,
  },
  {
    name: "USDT",
    Symbol: "/assets/images/currenciess/usdt.png",
    value: 4,
  },
  {
    name: "BUSD",
    Symbol: "/assets/images/currenciess/busd.png",
  },
  {
    name: "cUSD",
    Symbol: "/assets/images/currenciess/cusd.png",
  },
  {
    name: "CELO",
    Symbol: "/assets/images/currenciess/celo2.png",
  },
  {
    name: "cEUR",
    Symbol: "/assets/images/currenciess/ceur.png",
    value: 3,
  },
  {
    name: "BNB",
    Symbol: "/assets/images/currenciess/bnb.png",
  },
  {
    name: "MATIC",
    Symbol: "/assets/images/currenciess/matic.png",
  },
  {
    name: "DAI",
    Symbol: "/assets/images/currenciess/dai.png",
  },
  {
    name: "TRX",
    Symbol: "/assets/images/currenciess/trx.png",
  },
  {
    name: "USDJ",
    Symbol: "/assets/images/currenciess/usdj.png",
  },
  {
    name: "ETH",
    Symbol: "/assets/images/currenciess/eth.png",
  },
  {
    name: "WNT",
    Symbol: "/assets/images/currenciess/wnt.png",
  },
  {
    name: "USDC",
    Symbol: "/assets/images/currenciess/usdc.png",
  },
];


export const networkList = [
  {
    name: "BSC",
    value: 1,
  },
  {
    name: "ETH",
    value: 2,
  },
  {
    name: "POLYGON",
    value: 3,
  },
  {
    name: "CELO",
    value: 4,
  },
  {
    name: "ARBITRUM",
    value: 5,
  },
  {
    name: "AVALANCHE",
    value: 6,
  },
  {
    name: "HECOCHAIN",
    value: 7,
  },
  {
    name: "TRON",
    value: 8,
  },
];