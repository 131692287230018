/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { setOtp } from "../../../Redux/Reducers/formReducer";
import { OTPBox, OTPBoxsContainer } from "./styles";

let currentOTPIndex: number = 0;
const OTPInputs = () => {
  const { otp } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);
    dispatch(setOtp(newOTP));
  };

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <OTPBoxsContainer>
      {otp.map((_, index) => (
        <OTPBox
          ref={index === activeOTPIndex ? inputRef : null}
          type="number"
          key={index}
          onChange={handleChange}
          onKeyDown={(e) => handleOnKeyDown(e, index)}
          value={otp[index]}
        />
      ))}
    </OTPBoxsContainer>
  );
};

export default OTPInputs;
