export const theme = {
  colors: {
    textWhite: "#fff",
    textBlack: "#000",
    bgWhite: "#ffffff",
    bgBlack: "#000",
    primary: "#2042B8",
    secondary: "",
    textBackground:
      "linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%)",
    bgGradient: "linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%)",
    textGrey: "#52575C",
    primaryBorder: "#ECECEC;",
  },
  fontSize: {
    xs: "12px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "48px",
  },
  fontWeight: {
    semibold: 600,
    bold: 700,
  },
};
