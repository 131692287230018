import styled from "styled-components";
import { media } from "../respnsive";
import { theme } from "../theme";

export const Container = styled.div``;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.phone} {
    flex-direction: column;
    gap: 50px;

    &.center {
      flex-direction: column-reverse;
    }
  }
`;

interface FlexProp {
  flex?: string;
  mobileFlex?: string;
  padding?: string;
  width?: string;
  tabWidth?: string;
  direction?: string;
}
export const LeftContent = styled.div<FlexProp>`
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding-right: ${(props) => (props.padding ? props.padding : "0 50px 0 0")};
  flex: ${(props) => props.flex || "0.5"};
  img:nth-child(1) {
    width: 100%;

    ${media.tablet} {
      width: 80%;
    }
  }

  ${media.tablet} {
    padding-right: 0;
    flex-direction: ${(props) => props.direction};
  }
  ${media.phone} {
    flex: 1;
    flex-direction: ${(props) => props.direction};
  }
`;
export const RightContent = styled.div<FlexProp>`
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: 50px;
  position: relative;
  flex: ${(props) => props.flex || "0.5"};
  padding: ${(props) => props.padding};
  ${media.phone} {
    flex: 1;
    gap: 20px;
  }
  ${media.tablet} {
    gap: 20px;
  }

  img:nth-child(1) {
    width: 100%;
    height: 100%;
  }

  img:nth-child(2) {
    position: absolute;
    left: 40%;
    top: 20%;

    ${media.tablet} {
      width: 59px;
      height: 68px;
      left: 40%;
      top: 30%;
    }
  }

  & .video_link {
    position: absolute;
    top: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    ${media.tablet} {
      top: 50%;
    }

    span {
      font-style: normal;
      font-weight: ${theme.fontWeight.bold};
      font-size: ${theme.fontSize.lg};
      letter-spacing: 0.148862px;
      background: ${theme.colors.textBackground};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      ${media.tablet} {
        font-size: ${theme.fontSize.xs};
      }
    }
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: ${theme.fontWeight.bold};
  font-size: 33px;
  line-height: 57px;
  display: flex;
  align-items: center;
  letter-spacing: 0.562636px;
  background: ${theme.colors.textBackground};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.tablet} {
    font-size: ${theme.fontSize.lg};
    line-height: 24px;
    letter-spacing: 0.562636px;
  }
`;
export const Description = styled.div`
  width: 427px;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSize.md};
  line-height: 21px;
  color: ${theme.colors.textGrey};

  ${media.tablet} {
    font-size: ${theme.fontSize.xs};
    line-height: 21px;
    width: 275px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;

  ${media.phone} {
    margin-bottom: 0;
  }
`;
export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.xl};
  line-height: 57px;
  display: flex;
  align-items: center;
  letter-spacing: 0.562636px;

  ${media.tablet} {
    font-size: ${theme.fontSize.lg};
    line-height: 24px;
    letter-spacing: 0.562636px;
  }
`;
export const SectionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSize.md};
  line-height: 21px;
  color: ${theme.colors.textGrey};

  ${media.tablet} {
    font-size: ${theme.fontSize.xs};
    line-height: 21px;
    width: 275px;
    height: 66px;
  }
`;

export const ContentTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  display: flex;
  align-items: center;
  letter-spacing: 0.562636px;
  color: ${theme.colors.textGrey};

  ${media.tablet} {
    font-size: ${theme.fontSize.lg};
    line-height: 24px;
    letter-spacing: 0.562636px;
  }
`;
export const ContentSubTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.562636px;
  color: ${theme.colors.textGrey};

  ${media.tablet} {
    font-size: ${theme.fontSize.sm};
    line-height: 24px;
    letter-spacing: 0.562636px;
  }
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
`;

// gettting started stykes

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;

  ${media.phone} {
    flex-direction: column;
  }
`;

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 26.2061px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: 0.307177px;

  ${media.tablet} {
    font-size: ${theme.fontSize.sm};
    line-height: 19px;
  }
  ${media.phone} {
  }
`;

export const CardDesc = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;

  ${media.tablet} {
    letter-spacing: 0em;
    font-size: ${theme.fontSize.xs};
  }
`;

export const CardFooter = styled.div``;

// trust prtners
export const TrustPartnersSection = styled.div`
  position: relative;
  width: 100%;
  min-height: 551.65px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  background: #ededed;
  gap: 20px;

  img {
    width: 448px;
    height: 69.64px;
  }

  img.side_s {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }

  ${media.tablet} {
    min-height: 351.65px;

    img {
      width: 248px;
      height: 39.64px;
    }
  }
  ${media.phone} {
    min-height: 251.65px;
    padding: 30px;
    gap: 10px;

    img {
      width: 248px;
      height: 39.64px;
    }
  }
`;

export const PartnerTitle = styled.div`
  display: flex;
  font-style: normal;
  width: 531px;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.xl};
  line-height: 57px;
  display: flex;
  align-items: center;
  letter-spacing: 0.562636px;

  ${media.tablet} {
    font-size: ${theme.fontSize.lg};
    line-height: 24px;
    letter-spacing: 0.562636px;
    width: 331px;
  }
  ${media.phone} {
    font-size: ${theme.fontSize.sm};
    line-height: 14px;
    letter-spacing: 0.562636px;
    width: 231px;
  }
`;

// direct
export const CardContent = styled.div`
  display: flex;
  padding: 50px;
  ${media.tablet} {
    padding: 0;
  }
  ${media.phone} {
    flex-direction: column;
    gap: 10px;
  }
`;
export const CardLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 0.4;

  ${media.phone} {
    flex: 1;
    gap: 5px;
  }
`;
export const CardLeftTitle = styled.div`
  font-size: 38px;
  font-weight: ${theme.fontWeight.bold};
  line-height: 45px;
  letter-spacing: 0.4416188597679138px;
  text-align: left;
  color: ${theme.colors.textWhite};

  ${media.tablet} {
    font-size: ${theme.fontSize.lg};
    line-height: 28px;
  }
  ${media.phone} {
    font-size: ${theme.fontSize.sm};
  }
`;
export const CardLeftDesc = styled.div`
  font-size: ${theme.fontSize.sm};
  font-weight: ${theme.fontWeight.bold};
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #a7a7a7;
  ${media.tablet} {
    font-size: ${theme.fontSize.xs};
    line-height: 21px;
  }
`;
export const CardRightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.6;

  ${media.phone} {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
