function numFormatter(num: any) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const monthNames = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const sortByMonths = (data: any, currency?: string) => {
  const summedDuplicates = data?.chartData?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) => el?.ticker === cur.ticker && el?.xAxis === cur.xAxis
      );
    if (item) {
      item.yAxis = item?.zAxis
        ? Number(item?.zAxis) + Number(cur?.zAxis)
        : Number(item?.yAxis) + Number(cur?.yAxis);
      item.xAxis = cur.xAxis;
    } else
      acc.push({
        ticker: cur.ticker,
        yAxis: cur.zAxis
          ? Number(cur.zAxis).toFixed(2)
          : Number(cur.yAxis).toFixed(2),
        xAxis: cur.xAxis,
      });
    return acc;
  }, []);

  const filteredData = currency
    ? summedDuplicates?.map((item: any) => {
        if (item?.ticker === currency)
          return {
            ticker: item?.ticker,
            y: (parseInt(item.yAxis).toFixed(2)),
            x: item.xAxis,
          };
      })
    : summedDuplicates?.map((item: any) => {
        return { y: parseInt(item.yAxis), x: item.xAxis };
      });
  const newData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  filteredData?.map((item: any) => {
    filteredData?.map((dup: any) => {
      if (dup?.x === "Jan") newData[0] = Number(dup?.y);
      else if (dup?.x === "Feb") newData[1] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Mar") newData[2] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Apr") newData[3] = Math.abs(Number(dup?.y));
      else if (dup?.x === "May") newData[4] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Jun") newData[5] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Jul") newData[6] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Aug") newData[7] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Sep") newData[8] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Oct") newData[9] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Nov") newData[10] = Math.abs(Number(dup?.y));
      else if (dup?.x === "Dec") newData[11] = Math.abs(Number(dup?.y));
      else return 0;
    });
  });
  return newData;
};

export const sortByDay = (data: any, currency?: string) => {
  const summedDuplicates = data?.chartData?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) => el?.ticker === cur.ticker && el?.xAxis === cur.xAxis
      );
    if (item) {
      item.yAxis = item?.zAxis
        ? Number(item?.zAxis).toFixed(2) + Number(cur?.zAxis).toFixed(2)
        : Number(item?.yAxis).toFixed(2) + Number(cur?.yAxis).toFixed(2);
      item.xAxis = cur.xAxis;
    } else
      acc.push({
        ticker: cur.ticker,
        yAxis: cur.zAxis ? cur.zAxis : cur.yAxis,
        xAxis: cur.xAxis,
      });
    return acc;
  }, []);
  const filteredData = currency
    ? summedDuplicates?.map((item: any) => {
        if (item?.ticker === currency)
          return {
            ticker: item?.ticker,
            y: parseInt(item.yAxis).toFixed(2),
            x: item.xAxis,
          };
      })
    : summedDuplicates?.map((item: any) => {
        return { y: parseInt(item.yAxis), x: item.xAxis };
      });

  const newData = new Array(31).fill(0);

  filteredData?.map((item: any) => {
    filteredData?.map((dup: any) => {
      if (dup?.x === "1") newData[0] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "2") newData[1] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "3") newData[2] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "4") newData[3] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "5") newData[4] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "6") newData[5] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "7") newData[6] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "8") newData[7] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "9") newData[8] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "10") newData[9] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "11") newData[10] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "12") newData[11] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "13") newData[12] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "14") newData[13] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "15") newData[14] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "16") newData[15] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "17") newData[16] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "18") newData[17] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "19") newData[18] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "20") newData[19] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "21") newData[20] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "22") newData[21] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "23") newData[22] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "24") newData[23] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "25") newData[24] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "26") newData[25] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "27") newData[26] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "28") newData[27] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "29") newData[28] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "30") newData[29] = Math.abs(parseInt(dup?.y));
      else if (dup?.x === "31") newData[30] = Math.abs(parseInt(dup?.y));
      else return 0;
    });
  });

  return newData;
};

export const sortByYear = (data: any, currency?: string) => {
  const summedDuplicates = data?.chartData?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) => el?.ticker === cur.ticker && el?.xAxis === cur.xAxis
      );
    if (item) {
      item.yAxis = item?.zAxis
        ? Number(item?.zAxis).toFixed(2) + Number(cur?.zAxis).toFixed(2)
        : Number(item?.yAxis).toFixed(2) + Number(cur?.yAxis).toFixed(2);
      item.xAxis = cur.xAxis;
    } else
      acc.push({
        ticker: cur.ticker,
        yAxis: cur.zAxis ? cur.zAxis : cur.yAxis,
        xAxis: cur.xAxis,
      });
    return acc;
  }, []);

  const filteredData = currency
    ? summedDuplicates?.map((item: any) => {
        if (item?.ticker === currency)
          return {
            ticker: item?.ticker,
            y: parseInt(item.yAxis).toFixed(2),
            x: item.xAxis,
          };
      })
    : summedDuplicates?.map((item: any) => {
        return { y: parseInt(item.yAxis), x: item.xAxis };
      });

  const newData = new Array(11).fill(0);

  filteredData?.map((item: any) => {
    filteredData?.map((dup: any) => {
      if (dup?.x === "2020") newData[0] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2021") newData[1] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2022") newData[2] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2023") newData[3] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2024") newData[4] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2025") newData[5] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2026") newData[6] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2027") newData[7] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2028") newData[8] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2029") newData[9] = Math.abs(parseInt(dup?.y));
      if (dup?.x === "2030") newData[10] = Math.abs(parseInt(dup?.y));
      else return 0;
    });
  });

  return newData;
};
