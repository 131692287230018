import React, { FC, useState } from "react";

import { OnOffButtonContainer } from "./styles";

interface IOnAndOff {
  active: boolean;
  onClick?: any;
}

const OnAndOff: FC<IOnAndOff> = ({ active, onClick }) => {
  return (
    <OnOffButtonContainer
      className={`${active && "active"}`}
      onClick={onClick}
      active={active}
    >
      <span />
    </OnOffButtonContainer>
  );
};
export default OnAndOff;
