/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { DotLoader } from "react-spinners";
import { UnFilledButton } from "./styles";

interface FilledButtonProps {
  text: string;
  handleClick?: any;
  bg?: string;
  loading?: boolean;
}

const UnFilledbutton: FC<FilledButtonProps> = ({
  text,
  handleClick,
  bg,
  loading,
}) => (
  <UnFilledButton
    bg={bg}
    onClick={handleClick && handleClick}
    disabled={loading}
    loading={loading}
  >
    {loading ? <DotLoader color="#252525" size={20} /> : text}
  </UnFilledButton>
);

export default UnFilledbutton;
