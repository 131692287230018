import styled from "styled-components";

export const FailureConatainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    cursor: pointer;
  }

  &:hover > .popup {
    display: block;
  }
`;
export const ReasonConatainer = styled.div`
  display: none;
  position: absolute;
  width: 200px;
  height: auto;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
  z-index: 70;
  padding: 10px;
  color: #727272;
  border-radius: 5px;
`;
