import styled from "styled-components";
import { media } from "../../respnsive";

export const RouteContainer = styled.div`
  position: relative;
  gap: 100px;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #f8f8f8;
  padding: 20px;
  overflow-x: hidden;

  ${media.tablet} {
    padding: 20px;
  }
  ${media.phone} {
    padding: 50px 5px;
  }
`;

export const ChartConatiner = styled.div`
  width: 413.39px;
  height: 320px;
`;

interface flexProps {
  direction?: string;
  height?: string;
}
export const FlexContainer = styled.div<flexProps>`
  display: flex;
  width: 100%;
  height: ${(prop) => (prop.height ? prop.height : "60vh")};
  max-height: 100vh;
  margin-bottom: 20px;
  gap: 20px;

  ${media.tablet} {
    flex-direction: ${(props) => props.direction || "column"};
    height: 200vh;
  }
`;

export const FlexFullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  margin-bottom: 100px;

  ${media.tablet} {
    flex-direction: column;
    height: 1500px;
  }
`;
export const FlexAutoContainer = styled.div<flexProps>`
  display: flex;
  width: 100%;
  gap: 20px;

  ${media.tablet} {
    flex-direction: column;
    gap: 20px;
  }
`;

export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.tablet} {
    height: 100%;
  }
`;
export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  ${media.tablet} {
    height: 100%;
  }
`;

interface FlexProp {
  flex?: string;
  mobileFlex?: string;
  padding?: string;
  width?: string;
  tabWidth?: string;
}
export const LeftContent = styled.div<FlexProp>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: ${(props) => props.flex || "0.5"};
  padding: ${(props) => props.padding || "0 50px"};
  ${media.tablet} {
    margin-bottom: 50px;
    padding: 0;
    flex: ${(props) => props.mobileFlex || "1"};
    width: ${(props) => props.tabWidth};
  }
  ${media.phone} {
    width: ${(props) => props.width};
    padding: 0;
  }
`;
export const RightContent = styled.div<FlexProp>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: ${(props) => props.flex || "0.5"};
  padding: ${(props) => props.padding || "0 50px"};
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.tablet} {
    margin-bottom: 50px;
    padding: 0;
    flex: ${(props) => props.mobileFlex || "1"};
    width: ${(props) => props.tabWidth};
  }
  ${media.phone} {
    width: ${(props) => props.width};
  }
`;

export const ChartTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8a8a8a;
`;

interface InnerSidebarProp {
  toggle?: boolean;
}

export const InnerPageSideBar = styled.div<InnerSidebarProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 20px;
  // position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 90vh;
  background: rgba(32, 66, 184, 0.03);
  box-shadow: -6px 0px 5px rgba(32, 66, 184, 0.1);
  transition: right 0.5s ease-in-out;
  overflow-x: auto;

  ${media.tablet} {
    position: absolute;
    right: ${(props) => (props.toggle ? "0" : "-100%")};
    z-index: 100;
    backdrop-filter: blur(15px);
    gap: 20px;
    padding: 20px 20px;
  }

  ${media.phone} {
    width: 60%;
    position: fixed;
    height: 100vh;
    // background: rgba(32, 66, 184, 0.03);
  }
`;

export const SeeMore = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 18px;
    line-height: 43px;
    background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  ${media.tablet} {
    display: flex;
    padding: 10px;
  }
`;

export const BoxContainer = styled.div`
  width: 802px;
  height: 714px;
  left: 431px;
  top: 198px;
  margin: auto;
  padding: 50px;
  background: #ffffff;

  ${media.tablet} {
    padding: 20px;
  }

  ${media.phone} {
    padding: 50px 5px;
    width: 350px;
  }
`;

export const ContentContainer = styled.div`
  margin-bottom: 50px;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 30px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #52575c;
  }

  ${media.tablet} {
    margin-bottom: 20px;
    h1 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
      width: 50%;
    }
  }

  ${media.phone} {
    font-size: 16px;
    width: 90%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(32, 66, 184, 0.11);
  transform: rotate(0.08deg);
  width: 100%;
  height: 75px;
  background: #ffffff;

  ${media.tablet} {
    width: 500px;
  }

  ${media.phone} {
    width: 340px;
  }
`;

export const ImageContainer = styled.div`
  left: 33.47%;
  right: 64.31%;
  top: 40.23%;
  bottom: 57.91%;
`;

export const CardImage = styled.img`
  width: 32px;
`;

export const CardWrapper = styled.div`
  cursor: pointer;
  margin-left: 30px;

  :hover {
    text-decoration: underline;
  }

  ${media.phone} {
    margin-left: 20px;
  }
`;

export const CardTitle = styled.h1`
  left: 533px;
  top: 400px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;

  ${media.phone} {
    font-size: 10px;
  }
`;

export const CardDescription = styled.p`
  left: 533px;
  top: 424px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  ${media.phone} {
    font-size: 10px;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const CardButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 34px;
  gap: 10px;
  width: 84px;
  height: 30px;
  left: 1078px;
  top: 407px;
  background: #2042b8;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
`;
