/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface NavState {
  toggle: boolean;
  toggleFilter: boolean;
  toggleFilterChart: boolean;
  toggleGenerate: boolean;
  openInnerSidebar: boolean;
  currentSubnav: number;
  currentMainBodyNav: number;
  modal: number;
}

const initialState: NavState = {
  toggle: false,
  toggleFilter: false,
  toggleFilterChart: false,
  toggleGenerate: false,
  openInnerSidebar: false,
  currentSubnav: 1,
  currentMainBodyNav: 1,
  modal: 0,
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    toggleMobileNav: (state) => {
      state.toggle = !state.toggle;
    },
    toggleFilterBar: (state, action) => {
      state.toggleFilter = action.payload;
    },
    toggleFilterChartBar: (state, action) => {
      state.toggleFilterChart = action.payload;
    },
    toggleGenerateForm: (state, action) => {
      state.toggleGenerate = action.payload;
    },
    toggleInnerSidebar: (state, action) => {
      state.openInnerSidebar = action.payload;
    },
    handleSubnav: (state, action) => {
      state.currentSubnav = action.payload;
    },
    handleMainnav: (state, action) => {
      state.currentMainBodyNav = action.payload;
    },
    handleModal: (state, action) => {
      state.modal = action.payload;
    },
  },
});

export const {
  toggleMobileNav,
  toggleFilterBar,
  toggleGenerateForm,
  toggleInnerSidebar,
  toggleFilterChartBar,
  handleSubnav,
  handleMainnav,
  handleModal,
} = navSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.nav;

export default navSlice.reducer;
