import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '../../../respnsive';

export const CardContainer = styled.div`
	width: 509px;
	height: 169px;
	gap: 20px;
	padding: 20px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border: 1px solid #f3f3f3;
	border-radius: 20px;

	${media.tablet} {
		width: 309px;
		height: 105px;
		padding: 10px;
		gap: 10px;
	}
`;
export const Icon = styled.img`
	width: 35px;

	${media.tablet} {
		width: 24px;
	}
`;
export const LeftContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
export const Title = styled.span`
	font-weight: 400;
	font-size: 20px;
	line-height: 19px;
	display: flex;
	align-items: center;
	letter-spacing: 0.313234px;
	color: #000000;
	${media.tablet} {
		font-size: 16px;
	}
`;
export const Desc = styled.span`
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	text-align: center;
	color: #a7a7a7;

	${media.tablet} {
		font-size: 12px;
	}
`;

interface IconCardProps {
	icon: string;
	title: string;
	desc: string;
}
const IconCard: FC<IconCardProps> = ({ icon, title, desc }) => (
	<CardContainer>
		<Icon src={icon} />
		<LeftContent>
			<Title>{title}</Title>
			<Desc>{desc}</Desc>
		</LeftContent>
	</CardContainer>
);

export default IconCard;
