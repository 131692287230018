import React, { FC } from "react";
import styled from "styled-components";
import { media } from "../../../respnsive";
import { IoMdDocument } from "react-icons/io";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  color: #252525;
  height: 200px;
  gap: 10px;
`;

const Text = styled.p`
  ${media.phone} {
    font-size: 14px;
  }
`;

const Image = styled.div`
  svg {
    font-size: 100px;
  }
  ${media.phone} {
    svg {
      font-size: 60px;
    }
  }
`;

interface NoRecordI {
  text: string;
}

const NoRecords: FC<NoRecordI> = ({ text }) => {
  return (
    <Container>
      <Image>
        <IoMdDocument color="#e1e8ff" />
      </Image>
      <Text>{text}</Text>
    </Container>
  );
};

export default NoRecords;
