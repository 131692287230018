/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { DotLoader } from "react-spinners";
import { SquareFilledButton } from "./styles";

interface FilledButtonProps {
  text: string;
  handleClick?: any;
  bg?: string;
  icon?: string;
  loading?: boolean;
  disabled?: boolean;
}

const SqaureFilledbutton: FC<FilledButtonProps> = ({
  text,
  handleClick,
  bg,
  icon,
  loading,
  disabled,
}) => (
  <SquareFilledButton
    bg={bg}
    onClick={handleClick && handleClick}
    disabled={loading || disabled}
    isDisabled={loading || disabled}
    loading={loading && loading}
  >
    {icon && <img src={icon} alt="icon" />}
    {loading ? <DotLoader color="#ff6600" size={10} /> : text}
  </SquareFilledButton>
);

export default SqaureFilledbutton;
