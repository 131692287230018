import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../Redux/app/hooks';

const ScrollToTop = () => {
	const { pathname } = useLocation();
	const { currentSubnav } = useAppSelector((state) => state.nav);
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useLayoutEffect(() => {
		window.scrollTo(500, 500);
	}, [currentSubnav]);

	return null;
};

export default ScrollToTop;
