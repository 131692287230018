/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface analyticsState {
  gettingWalletsGenerated: Boolean;
  walletsGenerated: any;
  gettingTransactionMetrics: Boolean;
  transactionMetrics: any;
  totalDepositVolumeInUSD: any;
  totalWithdrawalVolumeInUSD: any;
  gettingRemittanceTimeMetrics: Boolean;
  remittanceTimeMetrics: any;
  gettingRemittanceNetworkTimeMetrics: Boolean;
  remittanceNetworkTimeMetrics: any;
  gettingRemittanceCurrencyTimeMetrics: Boolean;
  remittanceCurrencyTimeMetrics: any;
  gettingRemittanceFeeMetrics: Boolean;
  remittanceFeeMetrics: any;
  gettingUnRemittanceTrans: Boolean;
  unRemittanceTrans: any;
  gettingUnRemittanceDetails: Boolean;
  unRemittanceDetails: any;
  gettingMerchantUnremitedBal: Boolean;
  merchantUnremitedBal: any;
  totalUnremittedBalance: any;
}

const initialState: analyticsState = {
  gettingWalletsGenerated: false,
  walletsGenerated: null,
  gettingTransactionMetrics: false,
  transactionMetrics: null,
  totalDepositVolumeInUSD: null,
  totalWithdrawalVolumeInUSD: null,
  gettingRemittanceTimeMetrics: false,
  remittanceTimeMetrics: null,
  gettingRemittanceNetworkTimeMetrics: false,
  remittanceNetworkTimeMetrics: null,
  gettingRemittanceCurrencyTimeMetrics: false,
  remittanceCurrencyTimeMetrics: null,
  gettingRemittanceFeeMetrics: false,
  remittanceFeeMetrics: null,
  gettingUnRemittanceTrans: false,
  unRemittanceTrans: null,
  gettingUnRemittanceDetails: false,
  unRemittanceDetails: null,
  gettingMerchantUnremitedBal: false,
  merchantUnremitedBal: null,
  totalUnremittedBalance: 0.0,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setGettingWalletsGenerated: (state, action) => {
      state.gettingWalletsGenerated = action.payload;
    },
    setWalletsGenerated: (state, action) => {
      state.walletsGenerated = action?.payload;
    },
    setGettingTransactionMetrics: (state, action) => {
      state.gettingTransactionMetrics = action.payload;
    },
    setTransactionMetrics: (state, action) => {
      state.transactionMetrics = action?.payload;
      const depositTotal = action?.payload?.deposits?.currencies
        ?.map((item: any) => item.volumeInUsd)
        .reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);
      state.totalDepositVolumeInUSD = depositTotal;
      const withdrawalsTotal = action?.payload?.withdrawals?.currencies
        ?.map((item: any) => item.volumeInUsd)
        .reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);
      state.totalWithdrawalVolumeInUSD = withdrawalsTotal;
    },
    setGettingRemittanceTimeMetrics: (state, action) => {
      state.gettingRemittanceTimeMetrics = action.payload;
    },
    setRemittanceTimeMetrics: (state, action) => {
      state.remittanceTimeMetrics = action?.payload;
    },
    setGettingRemittanceNetworkTimeMetrics: (state, action) => {
      state.gettingRemittanceNetworkTimeMetrics = action.payload;
    },
    setRemittanceNetworkTimeMetrics: (state, action) => {
      state.remittanceNetworkTimeMetrics = action?.payload;
    },
    setGettingRemittanceCurrencyTimeMetrics: (state, action) => {
      state.gettingRemittanceCurrencyTimeMetrics = action.payload;
    },
    setRemittanceCurrencyTimeMetrics: (state, action) => {
      state.remittanceCurrencyTimeMetrics = action?.payload;
    },
    setGettingRemittanceFeeMetrics: (state, action) => {
      state.gettingRemittanceFeeMetrics = action.payload;
    },
    setRemittanceFeeMetrics: (state, action) => {
      state.remittanceFeeMetrics = action?.payload;
    },
    setGettingUnRemittanceTrans: (state, action) => {
      state.gettingUnRemittanceTrans = action.payload;
    },
    setUnRemittanceTrans: (state, action) => {
      state.unRemittanceTrans = action?.payload;
    },
    setGettingUnRemittanceDetails: (state, action) => {
      state.gettingUnRemittanceDetails = action.payload;
    },
    setUnRemittanceDetails: (state, action) => {
      state.unRemittanceDetails = action?.payload;
    },
    setGettingMerchantUnremitedBal: (state, action) => {
      state.gettingMerchantUnremitedBal = action.payload;
    },
    setMerchantUnremitedBal: (state, action) => {
      state.merchantUnremitedBal = action?.payload;
      const total = action?.payload?.balances
        ?.map((item: any) => item.amountInUSD)
        .reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);
      state.totalUnremittedBalance = total;
    },
  },
});

export const {
  setGettingWalletsGenerated,
  setWalletsGenerated,
  setGettingTransactionMetrics,
  setTransactionMetrics,
  setGettingRemittanceTimeMetrics,
  setRemittanceTimeMetrics,
  setGettingRemittanceNetworkTimeMetrics,
  setRemittanceNetworkTimeMetrics,
  setGettingRemittanceCurrencyTimeMetrics,
  setRemittanceCurrencyTimeMetrics,
  setGettingRemittanceFeeMetrics,
  setRemittanceFeeMetrics,
  setGettingUnRemittanceTrans,
  setUnRemittanceTrans,
  setGettingUnRemittanceDetails,
  setUnRemittanceDetails,
  setGettingMerchantUnremitedBal,
  setMerchantUnremitedBal,
} = analyticsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.analytics;

export default analyticsSlice.reducer;
