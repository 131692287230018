import styled from "styled-components";
import { media } from "../respnsive";

export const MainDashBoard = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
`;

export const AppBody = styled.div`
  position: relative;
  flex: 0.8;
  overfow-x: hidden;
  overflow-y: auto;
  overflow-x: hidden;

  ${media.tablet} {
    flex: 1;
  }
`;
