import styled from "styled-components";
import { media } from "../../../respnsive";

export const OTPBoxsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${media.phone} {
    gap: 10px;
  }
`;
export const OTPBox = styled.input`
  width: 45px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  text-align: center;
  spin-button: none;
  outline: none;

  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  ${media.phone} {
    width: 35px;
    height: 32px;
  }
`;
interface toggleButtonProp {
  active: boolean;
}

export const OnOffButtonContainer = styled.div<toggleButtonProp>`
  position: relative;
  width: 50px;
  height: 9px;
  background: rgba(32, 66, 184, 0.11);
  box-shadow: inset 0px 2px 4px rgba(32, 66, 184, 0.25);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;

  ${media.phone} {
    width: 40px;
  }

  span {
    position: absolute;
    width: 30px;
    height: 30px;
    left: ${(props) => (props.active ? "70%" : "-30%")};
    top: -100%;
    background: ${(props) =>
      props.active
        ? "linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%)"
        : "#e0e4f1"};
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-radius: 50%;
    transition: left 0.5s ease-in-out;

    ${media.phone} {
      width: 20px;
      height: 20px;
      left: ${(props) => (props.active ? "70%" : "-20%")};
      top: -60%;
    }
  }
`;
