import styled from "styled-components";

export const DateRange = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #757575;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //   height: 439px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #25282b;
`;
export const Content = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 70%;
  line-height: 18px;
  color: #757575;
`;
export const BorderLine = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
`;
export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;